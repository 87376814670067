<template>
  <div class="wrapper">
    <SimpleFilter
      :id.sync="formId"
      :list="formList"
      name="Forms"
      :no-filter="true"
      @update="getOverviewCharts"
    />

    <BaseScrollbar height="calc(100vh - 260px)">
      <div class="row items-start q-col-gutter-sm q-my-sm">
        <div class="col-2 q-col-gutter-sm">
          <template v-for="(item, index) in formCount">
            <div :key="index">
              <div class="cards chartBox">
                <div class="row items-center header">{{ item.label }}</div>
                <div class="column">
                  <div class="col self-center q-mt-lg q-mb-sm count">
                    {{ item.count }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="col-10 q-col-gutter-sm">
          <div class="row">
            <div class="col-8">
              <div class="cards q-mr-sm">
                <div class="row items-center header">
                  Product Trends by Month
                </div>

                <apexchart
                  type="line"
                  height="90%"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>

            <div id="workflow" class="col-4">
              <BaseScrollbar height="265px">
                <table id="workflowTable">
                  <thead>
                    <tr>
                      <!-- columns -->

                      <th
                        v-for="column in userColumns"
                        :key="column.id"
                        class="text-bold"
                      >
                        <template>
                          {{ column.label }}
                        </template>
                      </th>

                      <!-- ... -->
                    </tr>
                  </thead>

                  <tbody>
                    <template v-for="item in userTable">
                      <tr :key="item.id">
                        <!-- columns -->

                        <td v-for="column in userColumns" :key="column.id">
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            <template>
                              {{ item[column.name] }}
                            </template>
                          </slot>
                        </td>

                        <!-- ... -->
                      </tr>
                    </template>
                  </tbody>
                </table>
              </BaseScrollbar>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <div class="border-outline q-mr-xs">
                <PieChart
                  title="Action Tracking"
                  :x-data="[]"
                  :y-data="[32, 6, 15, 25]"
                />
              </div>
            </div>

            <div class="col-4">
              <div class="recentCard border-outline q-ml-xs q-mr-xs">
                <div class="row items-center">
                  <div class="col q-pa-sm">
                    <div class="text-base font-bold">RECENT ACTIVITIES</div>
                  </div>
                </div>
                <div class="q-pa-sm">
                  <div class="row items-center">
                    <div class="col-auto">
                      <BaseIcon
                        name="mdi-checkbox-marked-circle"
                        color="positive"
                        size="25px"
                      ></BaseIcon>
                    </div>
                    <div class="col q-ml-sm">
                      <div class="text-md">Document Approval</div>
                    </div>
                    <div class="col q-ml-sm">
                      <div class="label text-grey">4 mins ago</div>
                    </div>
                  </div>
                  <div class="q-ml-md q-mt-sm">
                    <div class="row items-center">
                      <div class="col-auto">
                        <q-avatar size="25px">
                          <img src="https://cdn.quasar.dev/img/avatar.png" />
                        </q-avatar>
                      </div>
                      <div class="col label text-grey q-ml-sm">
                        Sarah has rejected Business proposal document.docx
                      </div>
                    </div>
                  </div>
                </div>

                <div class="q-pa-sm">
                  <div class="row items-center">
                    <div class="col-auto">
                      <BaseIcon
                        name="eva-alert-triangle-outline"
                        color="negative"
                        size="25px"
                      ></BaseIcon>
                    </div>
                    <div class="col q-ml-sm">
                      <div class="text-md">Document Rejection</div>
                    </div>
                    <div class="col q-ml-sm">
                      <div class="label text-grey">4 mins ago</div>
                    </div>
                  </div>
                  <div class="q-ml-md q-mt-sm">
                    <div class="row items-center">
                      <div class="col-auto">
                        <q-avatar size="25px">
                          <img src="https://cdn.quasar.dev/img/avatar.png" />
                        </q-avatar>
                      </div>
                      <div class="col label text-grey q-ml-sm">
                        Sarah has rejected Business proposal document.docx
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="cards q-pa-md q-ml-xs">
                <div class="row items-center header">Recent Submissions</div>
                <div class="row items-center header-top q-mt-sm">
                  <div class="label col">Form name</div>
                  <div class="label col-auto">Last 90 days</div>
                </div>
                <div class="row items-center q-mb-sm">
                  <div class="col">Sales order</div>
                  <div class="col-auto row">
                    <div class="q-mr-md">79</div>
                    <BaseIcon name="mdi-triangle" color="positive" /> 5%
                  </div>
                </div>

                <div class="row items-center q-mb-sm">
                  <div class="col">Invoice Payable</div>
                  <div class="col-auto row">
                    <div class="q-mr-md">81</div>
                    <BaseIcon name="mdi-triangle" color="positive" /> 7%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
import SimpleFilter from "@/components/common/display/item-actions/components/SimpleFilter.vue";
import PieChart from "@/components/common/charts/PieChart.vue";
import { form, report } from "@/api/factory.js";

export default {
  name: "FormsOverview",

  components: { SimpleFilter, PieChart },

  data() {
    return {
      formId: 0,
      formList: [],
      series: [
        {
          name: "TASK",
          color: "#7e61c8",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 100, 125, 30],
        },
        {
          name: "MASTER",
          color: "#cec2ed",
          data: [2, 33, 12, 23, 50, 89, 46, 80, 110],
        },
        {
          name: "WORKFLOW",
          color: "#9d61c8",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 100, 125, 30],
        },
        {
          name: "SLA",
          color: "#cfb1e6",
          data: [2, 33, 12, 23, 50, 89, 46, 80, 110],
        },
      ],
      chartOptions: {
        chart: {
          height: "100%",
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        // grid: {
        //   row: {
        //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        //     opacity: 0.5,
        //   },
        // },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },

      pieSeries: [50, 50],
      pieChartOptions: {
        chart: {
          type: "donut",
        },
        title: {
          text: "",
          align: "left",
        },
        labels: ["Open", "Closed"],
        colors: ["#bfa0fe", "#75c1fd"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: false,
        },
      },
      formCount: [
        {
          label: "Total Master Forms",
          value: "master",
          count: 0,
        },
        {
          label: "Total Workflow Forms",
          value: "workflow",
          count: 0,
        },
        {
          label: "Total SLA Forms",
          value: "sla",
          count: 0,
        },
        {
          label: "Total Task Forms",
          value: "task",
          count: 0,
        },
      ],
      userColumns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Created User",
        },
        {
          id: this.$nano.id(),
          name: "totalForms",
          label: "Total Forms",
        },
        {
          id: this.$nano.id(),
          name: "totalEntries",
          label: "Total Entries",
        },
      ],
      userTable: [],
    };
  },

  created() {
    this.getForms();
    this.getAllFormCounts();
    // this.getAllFormGraph();
    this.getAllFormUserwiseCounts();
  },

  methods: {
    getOverviewCharts() {},

    async getForms() {
      this.$store.commit("showLoadingBar");

      let criteria = "createdBy";
      let value = this.$store.state.session.id;

      const { error, payload } = await form.getFormList(criteria, value);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      // console.log(payload);

      this.formList = payload.map((group) => ({
        id: this.$nano.id(),
        label: group.value,
        value: group.id,
      }));
    },

    async getAllFormCounts() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await report.getAllFormCounts();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.formCount.forEach((entry) => {
        const value = Object.keys(payload).find((key) => key === entry.value);

        if (value) {
          entry.count = payload[value];
        }
      });
    },

    async getAllFormGraph() {
      this.$store.commit("showLoadingBar");

      let year = new Date().getFullYear();

      const { error, payload } = await report.getAllFormtypeEntryGraph(year);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      console.log("graph", payload);
    },

    async getAllFormUserwiseCounts() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await report.getUserwiseAllFormEntryCounts();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.userTable = payload;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: calc(100vh - 208px);
}

.cards {
  height: 300px;
  // border: 1px solid #fff;
  padding: 10px;
  border-radius: 10px;
  // background: white;
  background-color: var(--component-bg-color);
  border: 1px solid var(--component-bg-color);

  &.chartBox {
    height: 150px;
  }
}

.border-outline {
  border-radius: 10px;
  border: 1px solid var(--component-bg-color);
}

.header {
  text-anchor: start;
  font-size: 15px;
  font-weight: 700;
  fill: rgb(55, 61, 63);
}

.header-top {
  border-bottom: 1px solid var(--divider-color);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.label {
  color: #a391cf !important;
}

.count {
  color: #8162cc;
  font-weight: 800;
  font-size: 24px;
}

.recentCard {
  height: 300px;
  // height: 100%;
  // border: 1px solid #fff;
  // padding: 10px;
  // background: white;
  background-color: var(--component-bg-color);
}

#workflow {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--component-bg-color);
  border: 1px solid var(--component-bg-color);

  #workflowTable {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    white-space: nowrap;
    width: 100%;

    tr {
      height: 36px;

      th {
        // color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border-bottom: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    tr:first-child th,
    tr:first-child td {
      border-top: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    td:first-child,
    th:first-child {
      border-left: 1px solid var(--divider-color); /* Remove left border from the first column */
    }

    td:last-child,
    th:last-child {
      border-right: 1px solid var(--divider-color); /* Remove right border from the last column */
    }
  }
}
</style>
